@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=61e4c003-a749-4d8f-9151-563b6a5ad2ee");
@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix");
  src: url("../fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix") format("eot"), url("../fonts/2cd55546-ec00-4af9-aeca-4a3cd186da53.woff2") format("woff2"), url("../fonts/1e9892c0-6927-4412-9874-1b82801ba47a.woff") format("woff"), url("../fonts/46cf1067-688d-4aab-b0f7-bd942af6efd8.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/d1fddef1-d940-4904-8f6c-17e809462301.eot?#iefix");
  src: url("../fonts/d1fddef1-d940-4904-8f6c-17e809462301.eot?#iefix") format("eot"), url("../fonts/7377dbe6-f11a-4a05-b33c-bc8ce1f60f84.woff2") format("woff2"), url("../fonts/92b66dbd-4201-4ac2-a605-4d4ffc8705cc.woff") format("woff"), url("../fonts/18839597-afa8-4f0b-9abb-4a30262d0da8.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/dccb10af-07a2-404c-bfc7-7750e2716bc1.eot?#iefix");
  src: url("../fonts/dccb10af-07a2-404c-bfc7-7750e2716bc1.eot?#iefix") format("eot"), url("../fonts/14c73713-e4df-4dba-933b-057feeac8dd1.woff2") format("woff2"), url("../fonts/b8e906a1-f5e8-4bf1-8e80-82c646ca4d5f.woff") format("woff"), url("../fonts/890bd988-5306-43ff-bd4b-922bc5ebdeb4.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/ac2d4349-4327-448f-9887-083a6a227a52.eot?#iefix");
  src: url("../fonts/ac2d4349-4327-448f-9887-083a6a227a52.eot?#iefix") format("eot"), url("../fonts/eaafcb26-9296-4a57-83e4-4243abc03db7.woff2") format("woff2"), url("../fonts/25e83bf5-47e3-4da7-98b1-755efffb0089.woff") format("woff"), url("../fonts/4112ec87-6ded-438b-83cf-aaff98f7e987.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
